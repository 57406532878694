<div class="page-wrapper">
  @if (user()) {
    <app-header></app-header>
  }
  <div class="content-main" [class.top-loader]="pageService.loading()">
    <div class="page-body">
      @if (user()) {
        <lib-shared-smart-ui-breadcrumb></lib-shared-smart-ui-breadcrumb>
      }
      <main>
        <!-- ensure that permissions are loaded -->
        @if (!loadingHolidays() || !user()) {
          <router-outlet></router-outlet>
        }
      </main>
    </div>
  </div>
</div>

<p-toast [baseZIndex]="2000"></p-toast>
